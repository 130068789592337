<template>
  <div>
    <div class="flix-badge">
      <a class="flix-html-a flix-text-warning" href="#" @click.prevent="open = !open">
        <flixIcon v-if="!open" :id="'plus'" />
        <flixIcon v-else :id="'minus'" />
      </a>
      <span v-if="open" style="position: relative">
        <div class="input">
          <input type="text" v-model='tag' :placeholder="placeholderDefault"/>
        </div>
        <a class="flix-html-a flix-text-warning flix-save" href="#" @click.prevent="addCategory()"><flixIcon :id="'share'" /> {{ $t('message.share', {name: ''}) }}</a>
      </span>
      <a v-if="open" href="#" class="tag" @click.prevent="tag = placeholder; addCategory()">{{ placeholder }}</a>
    </div>
    <div class="flix-badge" v-for="(badge, badgeID) in allTags" :key="badgeID">{{ badge }} <a href="#" @click.prevent="deleteCategory(badgeID)" class="flix-html-a flix-text-danger"><flixIcon :id="'remove'" /></a></div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    ressources: Array, index: Number
  },
  data () {
    return {
      open: false,
      tag: '',
      placeholderDefault: this.$t('message.email'),
      placeholder: '',
      allTags: []
    }
  },
  watch: {
    tag (value) { this.tag = value.split(' ').join('_') }
  },
  methods: {
    getPlaceholder () {
      if (this.placeholder) {
        return this.placeholder
      }
      return this.placeholderDefault
    },
    checkPlaceholder () {
      if (!this.tag.trim()) {
        this.placeholder = ''
        return false
      }
      this.placeholder = this.tag
    },
    deleteCategory (index) {
      this.allTags.splice(index, 1)
      this.setSaveShares()
    },
    addCategory (index) {
      this.tag = this.tag.toLowerCase().trim()
      if (!this.tag || this.allTags.indexOf(this.tag) !== -1) {
        return false
      }
      this.tag = this.tag.toLowerCase().trim()
      this.allTags.push(this.tag)
      this.setSendEmail(this.tag)
      this.tag = ''
      this.open = false
      this.setSaveShares()
    },
    getAllTags () {
      this.allTags = []
      if (typeof this.$store.getters.ressourcesConnect !== 'object' || !Object.keys(this.$store.getters.ressourcesConnect).length) {
        return false
      }
      if (typeof this.$store.getters.ressourcesConnect[this.ressources[this.index].ID] !== 'object' || !Object.keys(this.$store.getters.ressourcesConnect[this.ressources[this.index].ID]).length) {
        return false
      }
      if (typeof this.$store.getters.ressourcesConnect[this.ressources[this.index].ID].shares !== 'object' || !Object.keys(this.$store.getters.ressourcesConnect[this.ressources[this.index].ID].shares).length) {
        return false
      }
      this.allTags = {}
      this.$store.getters.ressourcesConnect[this.ressources[this.index].ID].shares.forEach(function (ressource) {
        this.allTags[ressource.email] = 1
      }.bind(this))
      this.allTags = Object.keys(this.allTags)
    },
    setSendEmail (email) {
      var link = this.$flix_url + '/shares/s/' + btoa(btoa(btoa(email)))
      var send = {}
      send.user = this.$getUserVariables().user.md5_id
      send.to = email
      send.title = this.$t('email.sharedEmail').title.split('{name}').join(this.ressources[this.index].title)
      send.text =
      this.$t('email.sharedEmail').text
        .split('{user}').join(this.$getUserVariables().user.username)
        .split('{name}').join(this.ressources[this.index].title)
        .split('{link}').join('<a href="' + link + '">' + link + '</a>')

      this.$flix_post({ url: 'ressources/sharingmail', data: send, callback: function (ret) {} })
    },
    setSaveShares () {
      var shares = []
      this.allTags.forEach(function (tag) {
        shares.push({ email: tag, shares: ['*'] })
      })
      var send = {
        user: this.$store.getters.user.md5_id,
        ID: this.ressources[this.index].ID,
        data: {
          shares: shares
        }
      }
      this.$flix_post({ url: 'ressources/connect/save_shares', data: send, callback: function (ret) { } })
    }
  },
  mounted () {
    this.getAllTags()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-badge
    display: inline-block
    margin-right: 5px
  .input
    display: inline-block

    z-index: 2
  input
    margin: 0 4px
    height: 12px
    width: 200px
    padding-left: 4px
    &::placeholder
      color: orange
  .tag
    font-weight: normal
    text-align: left
    color: white
    padding-left: 20px
    display: block
    margin-top: 2px
    font-size: 10pt

  @media (max-width: 500px)
    input
      width: 90px
    .flix-save
      font-size: 9pt
    .flix-badge
      display: block
      margin-top: 10px

</style>
